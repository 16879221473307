.dropdown {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.label {
  background-color: transparent;
  border: none;
  width: 100%;
  height: 100%;
  padding: 0;
}

.items {
  white-space: nowrap;
  width: fit-content;
  background-color: var(--background-offset);
  border-top: .1rem solid rgba(0, 0, 0, .25);

  svg {
    width: 1em;
    height: 1em;
  }

  button {
    background-color: transparent;
    border: none;
    width: 100%;
    text-align: left;
  }
}

.item {
  font-size: 1.4rem;
  border: .1rem solid rgba(0, 0, 0, .25);
  border-top: 0;
}

.itemChild {
  display: flex;
  align-items: center;
  padding: .75rem 1rem;
  gap: .75rem;
  font-size: 1.4rem;
  line-height: 1em;
}
