@keyframes spinner_animation {
  8.33% {
    x:13px;
    y:1px;
  }
  25% {
    x:13px;
    y:1px;
  }
  33.3% {
    x:13px;
    y:13px;
  }
  50% {
    x:13px;
    y:13px;
  }
  58.33% {
    x:1px;
    y:13px;
  }
  75% {
    x:1px;
    y:13px;
  }
  83.33% {
    x:1px;
    y:1px;
  }
}

.loader {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.spinner {
  width: 5rem;
  height: 5rem;
  fill: var(--light-blue);
}

.animation {
  animation: spinner_animation 2.4s linear infinite;
  animation-delay: -2.4s;
}

.delay1 {
  animation-delay: -1.6s;
}

.delay2 {
  animation-delay:-.8s;
}
