.sr-only {
  position: absolute;
  margin: -1px;
  padding: 0;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
}
