.tableCell {
  padding: 1.2rem 1.5rem;
}

.warning {
  background-color: var(--warning);
}

.error {
  background-color: var(--error);
}

.highest {
  font-weight: 500;
}
