.table {
  border-collapse: collapse;
  box-shadow: 0 0 2rem rgba(0, 0, 0, 0.15);
  border-radius: var(--border-radius);
  overflow: hidden;
  width: 100%;
  margin-bottom: 2rem;
}

.summary {
  padding: 1.2rem 1.5rem;
  text-align: left;
  border-top: .1rem solid var(--primary);
}

.row {
  border-bottom: .1rem solid #dddddd;

  &:nth-of-type(even) {
    background-color: var(--light-grey);
  }
}

.cell {
  padding: 1.2rem 1.5rem;
}

.warning {
  background-color: var(--warning);
}

.error {
  background-color: var(--error);
}

.highest {
  font-weight: bold;
}

.header {
  background-color: var(--primary);
  color: var(--white);
  text-align: left;
  font-weight: 600;
}
