.sequenceCard {
  border: .1rem solid var(--light-blue);
  border-radius: var(--border-radius);
  transition: background-color 360ms;
  background-color: var(--white);
}

.header {
  margin-bottom: .5rem;
  padding: 1.5rem 1.5rem 0;
  position: relative;
}

.title {
  margin-bottom: .5rem;
}

.extra {
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: 2.6rem;
  height: 2.6rem;
  padding: .5rem;
}

.extraLabel {
  color: var(--foreground-offset);
  width: 100%;
  height: 100%;
}

.delete {
  color: var(--danger);
}

.date, .report {
  font-size: 1.2rem;
  color: var(--grey);
}

.report {
  padding: 0 1.5rem 0;
  margin-bottom: 1.5rem;
}

.report, .reportEntries {
  display: flex;
  gap: 1.2rem;
}

.label {
  font-weight: 600;
}

.footer {
  display: flex;
  justify-content: space-between;
  background-color: rgba(0, 0, 0, .05);
}

.listItem {
  min-width: 0;
  flex: 1 1 100%;
  border: .1rem solid rgba(0, 0, 0, .05);
  border-bottom: 0;

  &:first-of-type {
    border-left: 0;
  }

  &:last-of-type {
    border-right: 0;
  }
}

.linkItem {
  display: block;
  padding: 1rem 1.5rem;

  &:hover {
    background-color: var(--light-blue);
  }
}
