.table {
  border-collapse: collapse;
  box-shadow: 0 0 2rem rgba(0, 0, 0, 0.15);
  border-radius: var(--border-radius);
  overflow: hidden;
  width: 100%;
  margin-bottom: 2rem;
}

.summary {
  font-size: 1.4rem;
  padding: 1.2rem 1.5rem;
  text-align: left;
  display: flex;
  align-items: center;

  &::after {
    content: "";
    flex: 1 1 auto;
    margin-left: 1.2rem;
    background-color: var(--primary);
    height: .1rem;
  }
}

.header {
  background-color: var(--primary);
  color: var(--white);
  text-align: left;
  font-weight: 600;
}

.row {
  border-bottom: .1rem solid #dddddd;

  &:nth-of-type(even) {
    background-color: var(--light-grey);
  }

  &:last-of-type {
    border-bottom: .2rem solid var(--primary);
  }
}

.cell {
  padding: 1.2rem 1.5rem;
}

.warning {
  background-color: var(--warning);
}

.error {
  background-color: var(--error);
}

.highest {
  font-weight: bold;
}
