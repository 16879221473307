.layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main {
  display: flex;
  flex-grow: 1;
  position: relative;

  @media screen and (max-width: calc(720 / 16 * 1rem)) {
    flex-direction: column;
    height: calc(100vh - var(--navbar-height));
  }
}

.sidebar {
  position: relative;

  @media screen and (max-width: calc(720 / 16 * 1rem)) {
    padding: 1rem 0 .5rem;
    flex: 0 0 15%;
  }

  @media screen and (min-width: calc(720 / 16 * 1rem)) {
    flex: 0 0 25%;
    padding: 1rem;
  }
}

.sidebarContent {
  @media screen and (max-width: calc(720 / 16 * 1rem)) {
    display: flex;
    gap: .5rem;
    overflow-x: auto;
    padding: 0 1rem;
  }
}

.sidebarContentItem {
  @media screen and (max-width: calc(720 / 16 * 1rem)) {
    flex: 0 0 80%;
  }
}

.content {
  position: relative;
  padding: 0 1rem 1rem 1rem;
  flex-grow: 1;

  @media screen and (min-width: calc(720 / 16 * 1rem)) {
    padding-top: 1rem;
    padding-left: 0;
  }
}
