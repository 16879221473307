.banner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  height: var(--navbar-height);
  color: var(--foreground);
  background-color: var(--light-blue);
  box-shadow: 0 .1rem .1rem var(--orange);
}

.logo {
  display: block;
  line-height: 0;
}

.img {
  height: 5rem;
}

.user {
  display: flex;
  align-items: center;
}

.account {
  margin-right: 1rem;
}

.signout {
  padding: .5rem 1rem;
  font-size: 1.4rem;
  font-weight: 500;
  color: var(--primary);
  text-transform: uppercase;
  background-color: transparent;
  border: .1rem solid var(--primary);
  border-radius: var(--border-radius);
}

.tenants {
  display: flex;
  align-items: flex-end;
  gap: 1rem;
  margin-right: 1.5rem;
}
