.title {
  font-size: 1.8rem;
  line-height: 1;
  font-weight: 500;
  margin-bottom: 2rem;

  &::before {
    content: '';
    display: inline-block;
    width: .7rem;
    height: 1.8rem;
    background-color: var(--primary);
    margin-right: .5rem;
    vertical-align: top;
  }
}

.sub {
  display: inline-block;
  vertical-align: bottom;
  font-size: 1.4rem;
  font-weight: 300;
}
