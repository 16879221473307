.container {
  position: relative;
}

.projectCard {
  display: block;
  padding: 1.5rem;
  padding-right: 3.1rem;
  border: .1rem solid var(--light-blue);
  border-radius: var(--border-radius);
  transition: background-color 360ms;
  background-color: var(--white);

  &:hover {
    background-color: var(--light-blue);
  }
}

.selected {
  background-color: var(--lighter-blue);
}

.title {
  color: var(--primary);
  margin-bottom: 2rem;
  font-weight: 700;
  text-transform: uppercase;
}

.license {
  font-size: 1.2rem;
  font-weight: 300;
}

.date {
  font-size: 1.2rem;
  font-weight: 500;
  color: var(--grey);
  text-transform: uppercase;
}

.actions {
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: 2.6rem;
  height: 2.6rem;
  padding: .5rem;
}

.actionsLabel {
  color: var(--foreground-offset);
  width: 100%;
  height: 100%;
}

.download {
  display: inline-block;
}

.delete {
  background: transparent;
  border: none;
  color: var(--danger);
}
