.toggle {
  padding: 0;
  border: none;
  width: 4rem;
  height: 2rem;
  border-radius: 2rem;
  background-color: #4e4e50;
  position: relative;
  cursor: pointer;
  transition: background-color 0.2s;
  appearance: none;
}

.toggleKnob {
  width: 1.9rem;
  height: 1.9rem;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  top: .05rem;
  left: .05rem;
  transition: left 0.2s;
}

.toggle[aria-pressed="true"] {
  background-color: #8d8d8e;
}

.toggle[aria-pressed="true"] .toggleKnob {
  left: calc(100% - 2rem);
}

