.select {
  display: inline-block;
  margin-right: .5rem;
}

.label {
  display: block;
  margin-bottom: .1rem;
  font-size: 1rem;
  text-transform: uppercase;
  text-align: right;
}

.elementContainer {
  display: block;
  position: relative;
  border: 1px solid var(--lighter-blue);
  border-radius: var(--border-radius);
  background-color: var(--white);

  &::after {
    position: absolute;
    right: 0;
    top: 0;
    width: 2.8rem;
    height: 100%;
    line-height: 2.8rem;
    content: "\25BE";
    text-align: center;
    color: var(--foreground);
    font-size: 1.6rem;
    border-left: .1rem solid var(--light-blue);
    pointer-events: none;
  }
}

.element {
  display: block;
  width: 100%;
  padding: .75rem 3.8rem .75rem 1rem;
  font-size: 1.4rem;
  color: var(--foreground);
  background: transparent;
  appearance: none;
  outline: none;
  border: none;
  border-radius: var(--border-radius);
}
