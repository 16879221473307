:root {
  // Colors
  --black: #000000;
  --white: #ffffff;
  --light-grey: #f2f3f6;
  --grey: #555555;
  --blue: #0a51a1;
  --dark-blue: #2d3e50;
  --light-blue: #d1dded;
  --lighter-blue: #dae3f1;
  --orange: #f1924c;
  --orange-dark: #bd580f;
  --pink: #fec8c8;
  --red: #fc9b9d;
  --danger: #d1161b;

  --background: var(--white);
  --background-offset: var(--lighter-blue);
  --foreground: var(--black);
  --foreground-offset: var(--grey);
  --primary: var(--blue);
  --accent: var(--orange);
  --warning: var(--pink);
  --error: var(--red);

  // Layout
  --navbar-height: 6rem;

  // Fonts
  --font-size: 1.6rem;
  --font-weight: 300;

  --body-font: 'Lexend', sans-serif;
  --heading-font: 'Anton', sans-serif;

  // Utils
  --border-radius: .3rem;
}

.dark-mode {
  --background: var(--black);
  --background-offset: var(--dark-blue);
  --foreground: var(--white);
  --foreground-offset: var(--lighter-blue);
  --primary: var(--blue);
  --accent: var(--orange);
  --warning: var(--pink);
  --error: var(--red);
}
